import axios from 'axios';
import store from '../store/index';
import router from '../router'
import { notify } from "@kyvg/vue3-notification";

const apiUrl = "/api";

axios.interceptors.request.use(
  // Add auth token to each controller call
  async (request) => {
    if (!store) return request;

    const user = await store.state.mgr.getUser();

    if (!user) return request;

    // This is incorrect for the api call!!!
    // Only use this if url starts with ${process.env.VUE_APP_BACKEND_URL}
    if (request.url.startsWith(`${process.env.VUE_APP_BACKEND_URL}`)) {
      // We are calling the web backend so add the access token
      const authToken = user.access_token;
      if (authToken) {
        request.headers.Authorization = `Bearer ${authToken}`;
      }
    }
    else if (request.url.includes(apiUrl)) {
      // We are calling the api so add the api token
      if (sessionStorage.apiKey) {
        request.headers.Authorization = `Bearer ${sessionStorage.apiKey}`;
      }
    }

    return request;
  });

axios.interceptors.response.use(
  // Successful response so do nothing
  response => response,
  error => {

    if (error.response && error.response.status === 403) {
      // handle a HTTP 403 Forbidden response by redirecting to the home page, which will also detect 
      // this condition and show the appropriate messages and hide the tabs that should be unavailable
      router.push('home');
    } else if (error.response && error.response.status === 401 && error.response.data.includes("expired")) {
      // handle the special case where the JWT has expired,
      notify({
        text: "Your API access token has expired",
        group: 'error',
        closeOnClick: "true",
      });
    } else {
      notify({
        text: error.response ? error.response.data.title : "",
        group: 'error',
        closeOnClick: "true",
      });
    }

    throw error;
  }
);

const getUsers = async function () {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/user`);

  return response.data;
};

const getUser = async function (id) {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/user/${id}`);

  return response.data;
};

const getUserToken = async function () {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/token`);

  return response.data;
};

const updateUser = async function (user) {
  const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/user`, user);

  return response.data;
};

const deleteUser = async function (userId) {
  await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/user/${userId}`);

  return userId;
};

const getServicesForUser = async function (id) {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/services/${id}`);

  return response.data;
};

const getEditableServicesForUser = async function (id) {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/editableservices/${id}`);

  return response.data;
};

const getAllEditableServices = async function () {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/editableservices`);

  return response.data;
};

const generateNewTokenForCurrentUser = async function () {
  const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/token`);

  return response.data;
};

const generateNewTokenForUser = async function (userId) {
  const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/token/${userId}`);

  return response.data;
};

const getCompanies = async function () {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/company`);

  return response.data;
};

const getCompany = async function (id) {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/company/${id}`);

  return response.data;
};

const updateCompany = async function (company) {
  const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/company`, company);

  return response.data;
};

const deleteCompany = async function (companyId) {
  await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/company/${companyId}`);

  return companyId;
};

const getApiDocs = async function (versionedAPIUrl) {
  const response = await axios.get(`${versionedAPIUrl}/OpenApi`);

  return response.data;
};

const getCSharpClient = async function (versionedAPIUrl) {
  const response = await axios.get(`${versionedAPIUrl}/OpenApi?fileType=CSharpClient`);

  return response.data;
};

const getModelChainRuns = async function () {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/modelChainRun`);

  return response.data;
};

const getModelChainRunsForPage = async function (perPage, currentPage, sortByField, sortDescending, filterText) {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/modelChainRun?perPage=${perPage}&currentPage=${currentPage}&sortByField=${sortByField}&sortDescending=${sortDescending}&filterText=${filterText}`);

  return response.data;
};

const getModelChainRun = async function (id) {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/modelChainRun/${id}`);

  return response.data;
};

const getModelChainRunAsyncFiles = async function (instanceId) {
  const response = await axios.get(`${apiUrl}/ModelChainAsync/?instanceid=${instanceId}`);

  return response.data;
};

const terminateModelChainRunAsync = async function (versionedAPIUrl, instanceId, reason) {
  await axios.post(`${versionedAPIUrl}/TerminateModelChainAsync?instanceid=${instanceId}&reason=${reason}`);
};

const getModelChainRunsExport = async function (fileType, sortByField, sortDescending, filterText) {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/modelChainRun/export?fileType=${fileType}&sortByField=${sortByField}&sortDescending=${sortDescending}&filterText=${filterText}`);

  return response.data;
};

const getAnalyticQueriesAndChartsDetails = async function () {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/AnalyticQueriesAndChartsDetails`);

  return response.data;
};

const getAnalyticQueryResults = async function (analyticQueryId, analyticQueryTimeRange) {
  const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/AnalyticQueryResults/${analyticQueryId}?analyticQueryTimeRange=${analyticQueryTimeRange}`);

  return response.data;
};

const sendPageViewTelemetry = async function (pageName) {
  const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/telemetry/pageview/${pageName}`);

  return response.data;
}

const sendEventTelemetry = async function (eventName, telemetry) {
  const response = await axios.put(`${process.env.VUE_APP_BACKEND_URL}/telemetry/event/${eventName}`,  telemetry);

  return response.data;
}

export const dataService = {
  getUsers,
  getUser,
  getUserToken,
  updateUser,
  deleteUser,
  getServicesForUser,
  getEditableServicesForUser,
  getAllEditableServices,
  generateNewTokenForCurrentUser,
  generateNewTokenForUser,
  getCompanies,
  getCompany,
  updateCompany,
  deleteCompany,
  getApiDocs,
  getCSharpClient,
  getModelChainRuns,
  getModelChainRunsForPage,
  getModelChainRun,
  getModelChainRunAsyncFiles,
  terminateModelChainRunAsync,
  getModelChainRunsExport,
  getAnalyticQueriesAndChartsDetails,
  getAnalyticQueryResults,
  sendPageViewTelemetry,
  sendEventTelemetry,
};
